import zIndex from "@mui/material/styles/zIndex";
import { Button, Grid, Typography } from "../../../components";
import { formatPrice } from "../../../utils";

const PdvTotalizadores = ({ venda, handleSubmit }) => {
  return (
    <Grid
      container
      className="table-pdv-footer pb-4 px-4"
      sx={{ zIndex: "888" }}
      fullWidth
    >
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">Total de Itens:</p>
        <p className="table-pdv-footer-label">
          {formatPrice(venda.totalMercadoria || 0)}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">
          {venda?.troco > 0 ? "Troco:" : "Saldo a pagar:"}
        </p>
        <p className="table-pdv-footer-label">
          {formatPrice(
            venda?.troco > 0
              ? venda.troco
              : venda.totalVenda - venda.totalPagamentos || 0
          )}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">Desconto:</p>
        <p className="table-pdv-footer-label">
          {formatPrice(venda.desconto || 0)}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">Valor pago:</p>
        <p className="table-pdv-footer-label">
          {formatPrice(venda?.totalPagamentos || 0)}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">Acréscimo:</p>
        <p className="table-pdv-footer-label">
          {formatPrice(venda.acrescimo || 0)}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        className="d-flex align-items-center justify-content-between px-3"
      >
        <p className="table-pdv-footer-label">Total:</p>
        <Typography
          variant="h2"
          className="text-black"
          style={{ fontWeight: 700 }}
        >
          {formatPrice(venda?.totalVenda || 0)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          id="submitFinalizarVenda"
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          color="success"
          sx={{
            p: "0.5rem",
            fontSize: 18,
            boxShadow: 0,
            fontWeight: 800,
          }}
        >
          FINALIZAR VENDA
        </Button>
      </Grid>
    </Grid>
  );
};

export default PdvTotalizadores;
